*,
*::after,
*::before {
    box-sizing: border-box;
    outline: none;
}

b {
    font-family: 'GothamProBold';
}

a {
    color: $fontColor;
    text-decoration: none;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: $fontColor;
        left: 0;
    }
}

body {
    background-color: $bgColor;
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: $fontColor;
    font-family: 'GothamProLight', sans-serif;
    font-weight: normal;
}

.page {
    margin-top: 60px;
    @media screen and (max-width: 765px) {
        margin-top: 30px;
    }
}
.medium {
    font-family: 'GothamProLight', sans-serif;
    font-weight: 600;
}
.mb-20 {
    margin-bottom: 20px;
    p {
        font-size: 15px;
    }
}
img.alredy{
 width: 80%;
} 
img.aboutdet{
    width: 80%;
    margin-top: 35px;
   } 