.plug {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 764px) {
        height: 80vh;
    }
    &__content {
        max-width: 780px;
        font-size: 27px;
        text-align: center;
        line-height: 140%;
        font-family: 'GothamProLight', sans-serif;
        font-weight: normal;
        display: none;
        &--pc {
            @media (min-width: 765px) {
                display: block;
            }
        }
        &--mobile {
            @media (max-width: 764px) {
                display: block;
            }
        }
    }
}