@import "../../styles/general/variables";

.header {
    width: 100%;
    margin-top: 45px;
    @media screen and (max-width: 765px) {
        margin-top: 25px;
    }
    &--fixed {
        position: fixed;
        margin-top: 0;
        top: 45px;
        left: 0;
    }
    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 765px) {
            justify-content: flex-start;
            padding-left: 25px;
        }
        img {
            max-width: 100px;
            @media screen and (max-width: 765px) {
                max-width: 50px;
            }
        }
        .divider {
            height: 65px;
            width: 1px;
            margin: 0 40px;
            background-color: $fontColor;
            @media screen and (max-width: 765px) {
                margin: 0 20px;
                height: 35px;
            }
        }
    }
}