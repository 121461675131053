@font-face {
    font-family: 'GothamProLight';
    font-weight: normal;
    src: url('../../assets/fonts/GothamProLight.eot');
    src: url('../../assets/fonts/GothamProLight.eot') format('embedded-opentype'),
         url('../../assets/fonts/GothamProLight.woff2') format('woff2'),
         url('../../assets/fonts/GothamProLight.woff') format('woff'),
         url('../../assets/fonts/GothamProLight.ttf') format('truetype'),
         url('../../assets/fonts/GothamProLight.svg#GothamProLight') format('svg');
}
@font-face {
    font-family: 'GothamProMedium';
    font-weight: 600;
    src: url('../../assets/fonts/GothamProMedium.eot');
    src: url('../../assets/fonts/GothamProMedium.eot') format('embedded-opentype'),
         url('../../assets/fonts/GothamProMedium.woff2') format('woff2'),
         url('../../assets/fonts/GothamProMedium.woff') format('woff'),
         url('../../assets/fonts/GothamProMedium.ttf') format('truetype'),
         url('../../assets/fonts/GothamProMedium.svg#GothamProMedium') format('svg');
}

@font-face {
    font-family: 'GothamProBold';
    font-weight: bold;
    src: url('../../assets/fonts/GothamProBold.eot');
    src: url('../../assets/fonts/GothamProBold.eot') format('embedded-opentype'),
         url('../../assets/fonts/GothamProBold.woff2') format('woff2'),
         url('../../assets/fonts/GothamProBold.woff') format('woff'),
         url('../../assets/fonts/GothamProBold.ttf') format('truetype'),
         url('../../assets/fonts/GothamProBold.svg#GothamProBold') format('svg');
}


@font-face {
    font-family: 'GothamProBlack';
    font-weight: bolder;
    src: url('../../assets/fonts/GothamProBlack.eot');
    src: url('../../assets/fonts/GothamProBlack.eot') format('embedded-opentype'),
         url('../../assets/fonts/GothamProBlack.woff2') format('woff2'),
         url('../../assets/fonts/GothamProBlack.woff') format('woff'),
         url('../../assets/fonts/GothamProBlack.ttf') format('truetype'),
         url('../../assets/fonts/GothamProBlack.svg#GothamProBlack') format('svg');
}
.regular {
    font-family: 'GothamProLight';
}
.medium {
    font-family: 'GothamProMedium';
}
.bold {
    font-family: 'GothamProBold';
}
.black {
    font-family: 'GothamProBlack';
}