.modal {
    &-header {
        margin-top: 80px;
        text-align: center;
        img {
            max-width: 150px;
        }
    }
    &-body {
        text-align: center;
        padding: 0 10px;
        margin: 50px 0;
        p {
            font-size: 18px;
            line-height: 110%;
            text-align: left;
            padding: 0 20px;
            span {
                // font-size: 21px;
                font-weight: bold;
            }
        }
        .success{
            padding: 10px 24px;
        }
    }
    &-footer {
        text-align: center;
        font-size: 21px;
        
    }
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100%);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-100px);
}